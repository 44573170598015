import classNames from 'classnames';
import { uniqueId } from 'lodash-es';
import { Checkbox, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChangeEvent, ComponentPropsWithoutRef, JSX, ReactNode } from 'react';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';

type PopoverMenuMultiselectItemType = {
  children: ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  prefix?: ReactNode;
  'data-testid'?: string;
} & ComponentPropsWithoutRef<'div'>;

function PopoverMenuMultiselectItem({
  children,
  onChange,
  isChecked = false,
  isDisabled = false,
  tooltip = null,
  prefix = null,
  'data-testid': dataTestId = null,
  ...htmlAttributes
}: PopoverMenuMultiselectItemType): JSX.Element {
  return (
    <li
      className={classNames('popover-menu-item', {
        'popover-menu-item--checked': isChecked,
      })}
      {...(dataTestId ? { 'data-testid': dataTestId } : null)}
    >
      <Checkbox onChange={onChange} checked={isChecked} disabled={isDisabled} {...htmlAttributes}>
        <ConditionalWrapper
          condition={!!tooltip}
          wrapper={(wrapperChildren) => (
            <OverlayTrigger
              placement="left"
              delayShow={700}
              overlay={<Tooltip id={uniqueId('filter-list-tooltip-')}>{tooltip}</Tooltip>}
            >
              {wrapperChildren}
            </OverlayTrigger>
          )}
        >
          <div className="popover-menu-item__wrap">
            {prefix && <span className="popover-menu-item__prefix">{prefix}</span>}
            <span className="popover-menu-item__text text-ellipsis">{children}</span>
            <em className="popover-menu-item__check">✓</em>
          </div>
        </ConditionalWrapper>
      </Checkbox>
    </li>
  );
}

export default PopoverMenuMultiselectItem;
