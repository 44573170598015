import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['default', 'multiselect']),
};
const defaultProps = {
  type: 'default',
};

function PopoverMenuList({ children, type }) {
  return <ul className={`popover-menu popover-menu--${type}`}>{children}</ul>;
}

PopoverMenuList.propTypes = propTypes;
PopoverMenuList.defaultProps = defaultProps;

export default PopoverMenuList;
