// TODO: This code is duplicated from resources/assets/js/packages/overview/constants/constants.js
// so in a seperate PR we will have to remove these constants from there and use these from this file
// since these are values that we are using around the whole application

export const GLOBAL_CONTENT_TYPES = {
  stories: 'stories',
  postsets: 'postsets',
  websitecontents: 'websitecontents',
  newsletters: 'newsletters',
  videoprojects: 'videoprojects',
  ebooks: 'ebooks',
  webinars: 'webinars',
  podcasts: 'podcasts',
} as const;

export const GLOBAL_CONTENT_TYPES_SLUGS = {
  [GLOBAL_CONTENT_TYPES.stories]: 'stories',
  [GLOBAL_CONTENT_TYPES.postsets]: 'postsets',
  [GLOBAL_CONTENT_TYPES.websitecontents]: 'websitecontents',
  [GLOBAL_CONTENT_TYPES.newsletters]: 'newsletters',
  [GLOBAL_CONTENT_TYPES.videoprojects]: 'videoprojects',
  [GLOBAL_CONTENT_TYPES.ebooks]: 'ebooks',
  [GLOBAL_CONTENT_TYPES.webinars]: 'webinars',
  [GLOBAL_CONTENT_TYPES.podcasts]: 'podcasts',
} as const;

export const GLOBAL_CONTENT_TYPES_LABELS = {
  [GLOBAL_CONTENT_TYPES.stories]: { singular: 'Article', plural: 'Articles' },
  [GLOBAL_CONTENT_TYPES.postsets]: { singular: 'Social post', plural: 'Social posts' },
  [GLOBAL_CONTENT_TYPES.websitecontents]: { singular: 'Webpage', plural: 'Webpages' },
  [GLOBAL_CONTENT_TYPES.newsletters]: { singular: 'Newsletter', plural: 'Newsletters' },
  [GLOBAL_CONTENT_TYPES.videoprojects]: { singular: 'Video ', plural: 'Videos' },
  [GLOBAL_CONTENT_TYPES.ebooks]: { singular: 'Ebook', plural: 'Ebooks' },
  [GLOBAL_CONTENT_TYPES.webinars]: { singular: 'Webinar', plural: 'Webinars' },
  [GLOBAL_CONTENT_TYPES.podcasts]: { singular: 'Podcast', plural: 'Podcasts' },
} as const;

export const GLOBAL_CONTENT_TYPES_MODELS = {
  [GLOBAL_CONTENT_TYPES.stories]: 'Story',
  [GLOBAL_CONTENT_TYPES.postsets]: 'Postset',
  [GLOBAL_CONTENT_TYPES.websitecontents]: 'WebsiteContent',
  [GLOBAL_CONTENT_TYPES.newsletters]: 'Newsletter',
  [GLOBAL_CONTENT_TYPES.videoprojects]: 'VideoProject',
  [GLOBAL_CONTENT_TYPES.ebooks]: 'Ebook',
  [GLOBAL_CONTENT_TYPES.webinars]: 'Webinar',
  [GLOBAL_CONTENT_TYPES.podcasts]: 'Podcast',
} as const;

export const GLOBAL_CONTENT_TYPES_ICONS = {
  [GLOBAL_CONTENT_TYPES.stories]: 'fa:file-text-o',
  [GLOBAL_CONTENT_TYPES.postsets]: 'custom:social',
  [GLOBAL_CONTENT_TYPES.websitecontents]: 'custom:webpage',
  [GLOBAL_CONTENT_TYPES.newsletters]: 'custom:newsletter',
  [GLOBAL_CONTENT_TYPES.videoprojects]: 'custom:video-project',
  [GLOBAL_CONTENT_TYPES.ebooks]: 'custom:e-book',
  [GLOBAL_CONTENT_TYPES.webinars]: 'custom:webinar',
  [GLOBAL_CONTENT_TYPES.podcasts]: 'custom:podcast',
} as const;

export const GLOBAL_CONTENT_TYPES_ENTITLEMENTS = {
  [GLOBAL_CONTENT_TYPES.stories]: 'stories',
  [GLOBAL_CONTENT_TYPES.postsets]: 'socials',
  [GLOBAL_CONTENT_TYPES.websitecontents]: 'content-types',
  [GLOBAL_CONTENT_TYPES.newsletters]: 'content-types',
  [GLOBAL_CONTENT_TYPES.videoprojects]: 'content-types',
  [GLOBAL_CONTENT_TYPES.ebooks]: 'content-types',
  [GLOBAL_CONTENT_TYPES.webinars]: 'content-types',
  [GLOBAL_CONTENT_TYPES.podcasts]: 'content-types',
} as const;

export type GlobalContentTypesModelsType = typeof GLOBAL_CONTENT_TYPES_MODELS;
