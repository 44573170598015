import classNames from 'classnames';
import { uniqueId } from 'lodash-es';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ComponentPropsWithoutRef, JSX, MouseEvent, ReactNode } from 'react';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import Button from '@/storychief/components/Button.tsx';

type PopoverMenuItemType = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  tooltip?: string;
  prefix?: ReactNode;
  children: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

function PopoverMenuItem({
  onClick,
  isDisabled = false,
  tooltip = null,
  prefix = null,
  children,
}: PopoverMenuItemType): JSX.Element {
  return (
    <li className="popover-menu-item">
      <ConditionalWrapper
        condition={!!tooltip}
        wrapper={(wrapperChildren) => (
          <OverlayTrigger
            placement="left"
            delayShow={700}
            overlay={<Tooltip id={uniqueId('filter-list-tooltip-')}>{tooltip}</Tooltip>}
          >
            {wrapperChildren}
          </OverlayTrigger>
        )}
      >
        <Button
          type="button"
          onClick={onClick}
          disabled={isDisabled}
          className={classNames('popover-menu-item__button', { 'disabled-item': isDisabled })}
        >
          {prefix && <span className="popover-menu-item__prefix">{prefix}</span>}
          <span className="popover-menu-item__text text-ellipsis">{children}</span>
        </Button>
      </ConditionalWrapper>
    </li>
  );
}

export default PopoverMenuItem;
