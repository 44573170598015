import PropTypes from 'prop-types';
import PopoverMenuItem from './PopoverMenuItem';
import PopoverMenuMultiselectItem from './PopoverMenuMultiselectItem';
import PopoverMenuList from './PopoverMenuList';
import PopoverMenuHeader from '@/storychief/components/PopoverMenuHeader';
import PopoverMenuFooter from '@/storychief/components/PopoverMenuFooter';

const propTypes = {
  isLoading: PropTypes.bool,
  hasMorePages: PropTypes.bool,
  onSearch: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.oneOf(['default', 'multiselect']),
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  loadingRef: PropTypes.func,
  scrollRef: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
const defaultProps = {
  isLoading: false,
  hasMorePages: false,
  onSearch: null,
  children: null,
  selectedItems: [],
  type: 'default',
  loadingRef: null,
  scrollRef: null,
  onSubmit: null,
  onCancel: null,
};

function PopoverMenu({
  isLoading,
  hasMorePages,
  children,
  type,
  selectedItems,
  onSearch,
  loadingRef,
  scrollRef,
  onSubmit,
  onCancel,
}) {
  function renderItems() {
    if (children?.length > 0) {
      return (
        <PopoverMenuList className="dropdown-menu" type={type}>
          {children}
        </PopoverMenuList>
      );
    }

    if (!isLoading) {
      return <div className="text-muted">No results found</div>;
    }

    return null;
  }

  return (
    <>
      <PopoverMenuHeader onSearch={onSearch} />
      <div className="popover-content-scroll" ref={scrollRef}>
        {renderItems()}
        {(isLoading || hasMorePages) && (
          <div className="text-muted space-1 space-top-1 text-center" ref={loadingRef}>
            <div>
              <span className="animated-spin d-inline-block">
                <span className="icon-spin1" />
              </span>{' '}
              Loading...
            </div>
          </div>
        )}
      </div>
      <PopoverMenuFooter onCancel={onCancel} onSubmit={onSubmit} selectedItems={selectedItems} />
    </>
  );
}

PopoverMenu.propTypes = propTypes;
PopoverMenu.defaultProps = defaultProps;

PopoverMenu.Item = PopoverMenuItem;
PopoverMenu.MultiselectItem = PopoverMenuMultiselectItem;
PopoverMenu.List = PopoverMenuList;

export default PopoverMenu;
