import { FormControl, FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  onSearch: PropTypes.func,
};
const defaultProps = {
  onSearch: null,
};

function PopoverMenuHeader({ onSearch }) {
  return onSearch ? (
    <div className="popover-content-header">
      <FormGroup controlId="name">
        <FormControl
          placeholder="Search..."
          autoComplete="off"
          type="text"
          onChange={(e) => onSearch(e.target.value)}
          autoFocus
        />
      </FormGroup>
    </div>
  ) : null;
}

PopoverMenuHeader.propTypes = propTypes;
PopoverMenuHeader.defaultProps = defaultProps;

export default PopoverMenuHeader;
