import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { JSX, MouseEvent } from 'react';
import Button from '@/storychief/components/Button.tsx';

type PopoverMenuFooterType = {
  selectedItems: {
    id: string;
    label: string;
  }[];
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
};

function PopoverMenuFooter({
  selectedItems = [],
  onSubmit = null,
  onCancel = null,
}: PopoverMenuFooterType): JSX.Element {
  return onSubmit || onCancel ? (
    <div className="popover-content-footer flex items-center justify-end gap-2">
      {selectedItems.length > 0 && (
        <OverlayTrigger
          placement="left"
          delayShow={300}
          overlay={
            <Tooltip id="popover-menu-footer-tooltip" bsStyle="lg">
              <ul className="m-0 pl-4">
                {selectedItems.map((item) => (
                  <li key={item.id}>{item.label}</li>
                ))}
              </ul>
            </Tooltip>
          }
        >
          <span className="text-muted text-xs">{selectedItems.length} selected</span>
        </OverlayTrigger>
      )}
      {onSubmit && (
        <Button
          size="sm"
          disabled={selectedItems.length === 0}
          onClick={onSubmit}
          data-testid="popover-footer-button-submit"
        >
          Add
        </Button>
      )}
      {onCancel && (
        <Button size="sm" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>
  ) : null;
}

export default PopoverMenuFooter;
